.title-en::after {
  content: "";
  display: block;
  width: 100px;
  height: 2px;
  background: #c7a76e;
  margin-top: 0.5rem;
}

.card:hover {
  -webkit-transform: scale(1.01);
  -moz-transform: scale(1.01);
  transform: scale(1.01);
}

.card:hover img {
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  transition: all 0.2s ease-in-out;
}

.card:hover h3 {
  @apply text-primary-dark;
  transition: all 0.2s ease-in-out;
}

ul li::marker {
  @apply text-primary;
}

a:hover {
  @apply text-primary;
}

.prose {
  max-width: none !important;
}

.fullHeight {
  height: calc(var(--vh, 1vh) * 100);
}
