@import url("https://fonts.googleapis.com/css2?family=Fredoka&display=swap");
@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c&display=swap");
@import url("assets/css/animations.css");
@import url("assets/css/base.css");
@import url("assets/css/common.css");

@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}
