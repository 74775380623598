div#root :not(.skip-fade),
ul :not(.skip-fade),
img :not(.skip-fade) {
  animation: fade-in 0.5s;
  -moz-animation: fade-in 0.5s;
  -webkit-animation: fade-in 0.5s;
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
